import React from 'react'
import GalleryBackground from '../../components/galleryBackground'
import GalleryGridFancy from '../../components/galleryGridFancy'
import cardRo from '../../images/other-art/cards-ro.png'
import "../layout.css"

import img1 from '../../images/other-art/card/1.jpg'
import img2 from '../../images/other-art/card/2.jpg'
import img3 from '../../images/other-art/card/3.jpg'
import img4 from '../../images/other-art/card/4.jpg'
import img5 from '../../images/other-art/card/5.jpg'
import img6 from '../../images/other-art/card/6.jpg'
import img7 from '../../images/other-art/card/7.jpg'
import img8 from '../../images/other-art/card/8.jpg'
import img9 from '../../images/other-art/card/9.jpg'
import img10 from '../../images/other-art/card/10.jpg'
import img11 from '../../images/other-art/card/11.jpg'
import img12 from '../../images/other-art/card/12.jpg'
import img13 from '../../images/other-art/card/13.jpg'
import img14 from '../../images/other-art/card/14.jpg'
import img15 from '../../images/other-art/card/15.jpg'
import img16 from '../../images/other-art/card/16.jpg'
import img17 from '../../images/other-art/card/17.jpg'
import img18 from '../../images/other-art/card/18.jpg'

export default function Collage() {
    let images = [img1, img2, img3, img4, img5, img6, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18]
    return (
        <GalleryBackground title="Cards" imgSrc={cardRo}>
            <GalleryGridFancy imgArray={images}/>
        </GalleryBackground>
    )
}
