import React from 'react'
import ImgModal from './imgModal'
import VideoModal from './videoModal';

export default function GalleryGridFancy({ imgArray, vidArray }) {
    const [open, setOpen] = React.useState(false);
    const [selectedImg, setSelectedImg] = React.useState(null)
    const handleOpen = (src) => {
        setSelectedImg(src)
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    const [vidOpen, setVidOpen] = React.useState(false);
    const [selectedVid, setSelectedVid] = React.useState(null)
    const handleOpenVid = (src) => {
        setSelectedVid(src)
        setVidOpen(true);
    }
    const handleVidClose = () => setVidOpen(false);


    return (
        <div className="fancy-gallery-image-container">
            <div className="fancy-gallery-grid">
                {
                    imgArray.map(imgSrc => {
                        return (
                            <div className="fancy-gallery-image" onClick={() => handleOpen(imgSrc)}>
                                <img src={imgSrc} />
                                <img src={imgSrc} />
                            </div>
                        )
                    })
                }
                {
                    vidArray !== undefined? (vidArray.map(vidSrc => {
                        return (
                        <div className="fancy-gallery-image" onClick={() => handleOpenVid(vidSrc.vid)}>
                            <img src={vidSrc.tab} />
                            <img src={vidSrc.tab} />
                        </div>
                        )
                    })) : (
                        null
                    )
                }
            </div>
            <ImgModal imgSrc={selectedImg} open={open} handleClose={handleClose}/>
            <VideoModal vidSrc={selectedVid} open={vidOpen} handleClose={handleVidClose}/>
        </div>
    )
}
