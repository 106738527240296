import React from 'react'
import MothButtons from './MothButtons'

export default function GalleryBackground({ children, title, imgSrc }) {
    return (
        <div className="gallery-bg">
            <MothButtons size="small" order={[
                {to: "/home", label: "Home"},
                {to: "/curriculum-vitae", label:  "CV"},
                {to: "/portfolio", label:  "Portfolio"},
                {to: "/contact", label:  "Contact"},
                ]}/>
            {
                imgSrc !== undefined ? (
                    <img className="gallery-heading-image" src={imgSrc} />
                ) : (
                    <h1 className="gallery-heading">{title}</h1>
                )
            }
            <div className="gallery-content">
                {children}
            </div>
        </div>
    )
}